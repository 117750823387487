import React from 'react';
import {camelizeKeys} from 'humps';
import {normalize} from 'normalizr';
import {jobSchema} from 'schemas/jobs';
import {connect} from 'react-redux';
import isEmpty from 'lodash.isempty';
import {bindActionCreators} from 'redux';
import {show} from 'redux-modal';
import {setConfirmRequire, closeTab} from 'actions/globalTabs';
import {merge} from 'actions/entities';
import {setProcessed} from 'actions/jobs/employees';
import {removeItem} from 'actions/lists';
import {
    downloadCommentsRequest,
    downloadTargetFileRequest,
    downloadBilingualExcelRequest,
    downloadBilingualTmxRequest,
    updateAnalysisRequest,
    reCommitRequest,
    showPreviewRequest,
    retrieveRequest
} from 'actions/mht';

import {sourcefileDownloadRequest} from 'actions/documents';
import {isMHTExitConfirmModalOpen, getMHTById, getMHTValueById} from 'selectors/mht';
import {isConfirmRequiredTab} from 'selectors/globalTabs';
import {MHT_EXIT_CONFIRM_MODAL, MHT_ANALYSIS_MODAL, EMPLOYEE_MHT_JOB_FINISH_MODAL} from 'misc/constants';
import {MHT_EDITOR} from 'misc/constants';
import {EMPLOYEE_PROCESS_MAP} from 'misc/config';

import {TaPane} from "components/taUi/taPane/taPane";
import MHTApi from "services/MHTApi";
import * as PropTypes from "prop-types";

import {TaCard} from "components/taUi/taCard/taCard";
import {TaTab} from "components/taUi/taTabs/taTab";
import {TaTabs} from "components/taUi/taTabs/taTabs";
import {TaMhtEditorTerms} from "components/mht/MHTEditor/TaMhtEditorTerms";
import {TaMhtEditorComments} from "components/mht/MHTEditor/TaMhtEditorComments";
import {TaMhtEditorMemories} from "components/mht/MHTEditor/TaMhtEditorMemories";
import {TaMhtEditorRevisions} from "components/mht/MHTEditor/TaMhtEditorRevisions";

import TaMhtEditorSettingsMemory from "components/mht/MHTEditor/TaMhtEditorSettingsMemory";
import TaMhtEditorSettingsTerm from "components/mht/MHTEditor/TaMhtEditorSettingsTerm";
import delay from "components/taUi/util/delay";
import TaMhtEditorImportExcelModal from "components/mht/MHTEditor/TaMhtEditorImportExcelModal";
import TaMhtEditorPreTranslateModal from 'components/mht/MHTEditor/TaMhtEditorPreTranslateModal';

import './ckEditorConfig/ckEditor.css';
import editorStyles from './TaMhtEditor.module.css';

import TaMhtEditorSaveWarningModal from "components/mht/MHTEditor/TaMhtEditorSaveWarningModal";
import {getCurrentTab} from "selectors/globalTabs";

import plainTextToHtml from "components/taUi/util/plainTextToHtml";
import {TaMhtEditorSegments} from "components/mht/MHTEditor/TaMhtEditorSegments";
import {TaMhtEditorToolbar} from "components/mht/MHTEditor/TaMhtEditorToolbar";
import chunkArray from "components/taUi/util/chunkArray";
import {TaMhtEditorWidgets} from "./TaMhtEditorWidgets";
import {convertHtmlToText} from "./textTools";
import TaMhtEditorMachineTranslateModal from "./TaMhtEditorMachineTranslateModal";
import TaMhtEditorConfirmActionModal from "./TaMhtEditorConfirmActionModal";
import TaMhtEditorReCommitTmModal from "./TaMhtEditorReCommitTmModal";
import TaMhtEditorDownloadTargetFileModal from "./TaMhtEditorDownloadTargetFileModal";

const _mutationsData = 'data';
const _mutationsIndex = 'index';
const _mutationOld = 'old';
const _mutationNew = 'new';

export class MHTEditor extends React.PureComponent {

    static propTypes = {
        title: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    };

    constructor(props) {
        super(props);

        this.itemsChunkSize = 50;
        this.targetInputChanged = false;

        this.searchDebounce = null;
        this.probeSourceInterval = null;

        this.defaultSearch = () => {
            return {
                string: '',
                context: 'source',
                isValid: false
            };
        };

        this.defaultSelectedSegment = (segment) => {
            let data = {
                time: Date.now(),
                item: '',
                items: {},
                count: 0
            };
            if (segment) {
                data.item = segment.id;
                data.items[segment.id] = segment;
                data.count = 1;
            }
            return data;
        };

        this.rebuildSelectedSegments = (allowedIds) => {

            const state = this.state;

            const ids = (typeof allowedIds !== 'undefined') ? allowedIds || [] : state.items.all;
            const currentSelectedSegment = state.selectedSegment;
            let updatedSelectedSegment = this.defaultSelectedSegment();

            ids.forEach(id => {
                if (
                    currentSelectedSegment.items[id]
                ) {
                    updatedSelectedSegment.item = id;
                    updatedSelectedSegment.items[id] = state.items.data[id];
                    updatedSelectedSegment.count += 1;
                }
            });
            if (updatedSelectedSegment.items[currentSelectedSegment.item]) {
                updatedSelectedSegment.item = currentSelectedSegment.item;
            }

            return updatedSelectedSegment;
        }

        this.defaultSelectedSource = () => {
            return {
                hasFocus: false,
                hasSelection: false,
                segmentId: '',
                editor: null
            };
        };

        this.defaultMutations = () => {
            return {
                time: 0,
                count: 0,
                index: {},
                data: {}
            };
        };

        this.defaultComments = {
            time: 0,
            index: {},
            items: []
        };

        this.tabId = 0;
        this.keyListener = false;
        this.panelTabsRef = React.createRef();

        this.state = {
            timeLoaded: 0,
            timeMutated: 0,
            timeSearched: 0,
            timeFiltered: 0,
            timeCommented: 0,

            items: {
                count: 0,
                data: {},
                chunks: [],
                all: []
            },
            isLoading: true,
            isSaving: false,
            mht: {},
            mutations: this.defaultMutations(),
            comments: Object.assign({}, this.defaultComments),

            selectedSegment: this.defaultSelectedSegment(),
            selectedSource: this.defaultSelectedSource(),
            selectedTarget: this.defaultSelectedSource(),
            shiftKey: false,

            search: this.defaultSearch(),
            filter: false,
            filteredItems: {
                count: 0,
                all: [],
                chunks: []
            },
            highlight: {},

            triggerTermModal: 0,
            triggerCommentModal: 0,

            openTargetFileDownloadModal: false,
            openMemorySettingsModal: false,
            openTermSettingsModal: false,
            openImportExcelModal: false,
            openPreTranslateModal: false,
            openMachineTranslateModal: false,
            openSaveWarningModal: false,
            saveWarningModalType: 'lost',

            warningModal: null,
            reCommitTmModal: null,
        };

        this.onSearchRequest = (search) => {
            const _runFilterRequest = () => {
                const filteredItems = this.filterSegments(search);
                this.setState({
                    isLoading: false,
                    search: search,
                    filteredItems: filteredItems,
                    timeSearched: (search.isValid) ? Date.now() : 0,
                    selectedSegment: (search.isValid) ? this.rebuildSelectedSegments(filteredItems.all) : this.rebuildSelectedSegments()
                }, () => {
                    if (!search.isValid) {
                        this.scrollToSelectedSegment();
                    }
                });
            }
            this.setState({
                isLoading: true
            }, () => setTimeout(_runFilterRequest, 100));
        };

        this.onFilterRequest = (filter) => {
            // console.log('onFilterRequest', filter);
            const _runFilterRequest = () => {
                const filteredItems = this.filterSegments(undefined, filter);
                this.setState({
                    isLoading: false,
                    filter: filter,
                    filteredItems: filteredItems,
                    timeFiltered: (filter !== false) ? Date.now() : 0,
                    selectedSegment: (filter !== false) ? this.rebuildSelectedSegments(filteredItems.all) : this.rebuildSelectedSegments()
                }, () => {
                    if (!filter) {
                        this.scrollToSelectedSegment();
                    }
                });
            }
            this.setState({
                isLoading: true
            }, () => setTimeout(_runFilterRequest, 250));
        };

        this.onResetFilterWidgetRequest = () => {
            const _runFilterRequest = () => {
                this.setState({
                    isLoading: false,
                    filter: false,
                    search: {
                        string: '',
                        context: this.state.search.context || 'source',
                        isValid: false
                    },
                    filteredItems: {
                        count: 0,
                        all: [],
                        chunks: []
                    },
                    timeSearched: 0,
                    timeFiltered: 0
                });
                this.scrollToSelectedSegment();
            }
            this.setState({
                isLoading: true
            }, () => setTimeout(_runFilterRequest, 250));
        }

        this.loadSegments = () => {

            return MHTApi
                .listAllSegments(props.params.id)
                .then(response => {

                    let segments = {};
                    let all = [];
                    let chunks = [];

                    response.data.forEach((segment, index) => {
                        segments[segment.id] = segment;
                        if (
                            !index ||
                            index === this.itemsChunkSize * chunks.length
                        ) {
                            chunks.push([]);
                        }
                        chunks[chunks.length - 1].push(segment.id)
                        all.push(segment.id)
                    })

                    return {
                        items: {
                            count: response.data.length,
                            data: segments,
                            all: all,
                            chunks: chunks
                        },
                    };
                });
        };

        this.saveSegments = () => {

            const params = props.params;
            const state = this.state;
            const mutations = state.mutations[_mutationsData];

            return new Promise((resolve, reject) => {

                this.setState({
                    isSaving: true
                }, () => {

                    let payload = [];
                    state.items.all.map(segmentId => {
                        if (typeof mutations[segmentId] !== 'undefined') {
                            const item = state.items.data[segmentId];
                            payload.push({
                                id: segmentId,
                                source_html: item.source_html,
                                target_html: item.target_html,
                                old_target_text: item.target_text,
                                status: item.status,
                                is_locked: item.is_locked,
                                is_totra: item.is_totra,
                                ...mutations[segmentId].new
                            });
                        }
                    });

                    return MHTApi.saveSegments(
                        params.id,
                        params.role,
                        payload
                    )
                        .then(() => {
                            return this.loadSegments();
                        })
                        .then((segmentsState) => {
                            this.setState(
                                {
                                    isSaving: false,
                                    ...segmentsState,
                                    selectedSegment: this.defaultSelectedSegment(),
                                    selectedSource: this.defaultSelectedSource(),
                                    mutations: this.defaultMutations(),
                                    filteredItems: this.filterSegments(undefined, undefined, segmentsState.items)
                                },
                                () => {
                                    resolve(true);
                                });
                        })
                        .catch(error => {
                            console.error('failed to save', error);
                            this.setState({
                                isSaving: false
                            });
                            reject(error);
                        });

                });

            });

        };

        this.filterSegments = (newSearch, newFilter, newItems) => {
            const state = this.state;
            const items = (typeof newItems !== 'undefined') ? newItems : state.items;
            const search = (typeof newSearch !== 'undefined') ? newSearch : state.search;
            const filter = (typeof newFilter !== 'undefined') ? newFilter : state.filter;
            const searchString = (!!search.string) ? search.string.toLowerCase() : '';

            if (!(search && search.isValid || filter !== false)) {
                return {
                    count: 0,
                    all: [],
                    chunks: []
                }
            } else {
                const filteredItems = items.all.filter(segmentId => {

                    const segment = items.data[segmentId];
                    let matchFilter = true;
                    let matchSearch = true;

                    if (filter) {
                        if (filter.property === 'status') {
                            matchFilter = (segment.status === filter.value);
                        } else if (filter.property === 'filter') {
                            switch (filter.value) {
                                case 'confirmed':
                                    matchFilter = MHTApi.isSegmentConfirmed(this.props.params.role, this.pickSegmentProperty(segment, 'status'));
                                    break;
                                case 'not_confirmed':
                                    matchFilter = !MHTApi.isSegmentConfirmed(this.props.params.role, this.pickSegmentProperty(segment, 'status'));
                                    break;
                                case 'locked':
                                    matchFilter = !!(this.pickSegmentProperty(segment, 'is_locked'));
                                    break;
                                case 'not_locked':
                                    matchFilter = !(this.pickSegmentProperty(segment, 'is_locked'));
                                    break;
                                case 'totra':
                                    matchFilter = !!(this.pickSegmentProperty(segment, 'is_totra'));
                                    break;
                                case'not_totra':
                                    matchFilter = !(this.pickSegmentProperty(segment, 'is_totra'));
                                    break;
                                case 'has_comment':
                                    matchFilter = !!(state.comments.index[segmentId]);
                                    break;
                                case 'has_no_comment':
                                    matchFilter = !(state.comments.index[segmentId]);
                                    break;
                                default:
                                    matchFilter = true;
                            }
                        }
                    }

                    if (
                        matchFilter &&
                        search.isValid
                    ) {
                        const mutatedHtmlProperty = this.pickMutatedSegmentProperty(segment, search.context + '_html');
                        let searchValue;
                        if (mutatedHtmlProperty) {
                            searchValue = convertHtmlToText(mutatedHtmlProperty)
                        } else {
                            searchValue = segment[search.context + '_text'];
                        }
                        matchSearch = searchValue.toLowerCase().includes(searchString);
                    }

                    return (matchFilter && matchSearch);

                });
                return {
                    count: filteredItems.length,
                    all: filteredItems,
                    chunks: chunkArray(filteredItems, this.itemsChunkSize)
                }
            }

        };

    }

    componentDidMount() {
        //todo: remove
        // this.props.params.role = 'translator';
        // this.props.params.role = 'coordinator';

        this.props.retrieveRequest(this.props.params.id);

        this.tabId = `${MHT_EDITOR}/${this.props.params.id}`;
        this.isConfirmRequiredRole = MHTApi.userRoleCanDo(this.props.params.role, 'confirm');
        this.props.setConfirmRequire(this.tabId, this.isConfirmRequiredRole);

        // todo: timeout is a hack... componentDidMount is called twice
        this.loadTimeout = setTimeout(() => {
            Promise
                .all([
                    this.loadSegments(),
                    this.loadComments()
                ])
                .then(response => {
                    this.setState({
                        timeLoaded: Date.now(),
                        ...response[0],
                        comments: response[1],
                        isLoading: false
                    });
                });
        }, 100);

    }

    componentWillUnmount() {
        clearTimeout(this.loadTimeout);
        this.unregisterKeyPressListener();
    }

    componentDidUpdate(prevProps, prevState) {

        const props = this.props;
        const state = this.state;

        if (this.tabHasFocus()) {
            this.registerKeyPressListener();
        } else {
            this.unregisterKeyPressListener();
        }

        const isConfirmRequiredTab = props.isConfirmRequiredTab;
        const hasMutation = state.mutations.count > 0;
        const hasMutationChanged = state.mutations.count !== prevState.mutations.count;

        if (
            hasMutation &&
            hasMutationChanged &&
            isConfirmRequiredTab === false
        ) {
            this.props.setConfirmRequire(this.tabId, true);
        } else if (
            !hasMutation &&
            hasMutationChanged &&
            isConfirmRequiredTab === true &&
            this.isConfirmRequiredRole === false
        ) {
            this.props.setConfirmRequire(this.tabId, false);
        }

        if (
            props.isConfirmRequiredTab &&
            props.isConfirmOpen &&
            prevProps.isConfirmOpen !== props.isConfirmOpen &&
            this.tabId === this.props.focusedTab
        ) {
            if (hasMutation) {
                this.showSaveModalContent(this.isConfirmRequiredRole);
            } else if (!hasMutation && this.isConfirmRequiredRole) {
                this.showFinishModalContent();
            }
        }

        if (
            prevState &&
            prevState.selectedSource &&
            prevState.selectedSource.hasFocus !== state.selectedSource.hasFocus
        ) {
            if (state.selectedSource.hasFocus) {
                this.probeSourceInterval = window.setInterval(this.probeSource, 250);
            } else if (this.probeSourceInterval) {
                clearInterval(this.probeSourceInterval)
            }
            // this.watchSourceSelection(state.selectedSource.hasFocus)
        }

    }

    // watchSourceSelection = (active) => {
    //   const selectedSource = this.state.selectedSource;
    //   console.log('watchSourceSelection',selectedSource.editor)
    //   if (selectedSource.editor) {
    //     selectedSource.editor.model.document.on('selectionChange', (eventInfo, data) => {
    //       console.log('bar'); // This won't be called.
    //     });
    //   }
    // }

    probeSource = () => {
        const selectedSource = this.state.selectedSource;
        const selectedText = selectedSource.editor.model.document.selection.getFirstRange();
        const hasSelection = !!(selectedText.end.offset - selectedText.start.offset);

        if (hasSelection !== selectedSource.hasSelection) {
            this.setState({
                selectedSource: {
                    ...selectedSource,
                    hasSelection: hasSelection
                }
            })
        }

    }

    showSaveModalContent = (canConfirm) => {
        const modalConfig = {
            data: {tabId: this.tabId},
            title: 'Unsaved Changes',
            body: (
                <React.Fragment>
                    Would you like to save changes to this document before closing?<br/>
                    Your changes will be lost if you don't save them.
                </React.Fragment>
            ),
            primaryActionLabel: 'Save',
            primaryActionIcon: 'check',
            tertiaryActionLabel: 'Don\'t Save',
            tertiaryActionIcon: 'delete',
            handlePrimaryAction: this.saveSegments
        };
        if (canConfirm) {
            const {id, role} = this.props.params;
            modalConfig['secondaryActionLabel'] = 'Save & Finish';
            modalConfig['secondaryActionIcon'] = 'check';
            modalConfig['handleSecondaryAction'] = () => {
                return this.saveSegments()
                    .then(() => this.handleMHTJobFinish(id, role))
                    .catch(error => {
                        if (!isEmpty(error.response)) {
                            modalConfig['error'] = error.response.data.detail;
                            this.props.show(MHT_EXIT_CONFIRM_MODAL, modalConfig);
                        }
                    });
            };
        }
        this.props.show(MHT_EXIT_CONFIRM_MODAL, modalConfig);
    };

    showFinishModalContent = () => {
        const {id, role} = this.props.params;
        const modalConfig = {
            data: {tabId: this.tabId},
            title: 'Finish MHT job',
            primaryActionLabel: 'Yes, Confirm & Finish',
            primaryActionIcon: 'check',
            secondaryActionLabel: 'No',
            secondaryActionIcon: 'close',
            error: undefined,
            body: (
                <React.Fragment>
                    Would you like to confirm all the segments and finish MHT job?<br/>
                    This will mark <i>all</i> segments as confirmed.
                </React.Fragment>
            ),
            handlePrimaryAction: () => {
                return this.handleMHTJobFinish(id, role)
                    .catch(error => {
                        if (!isEmpty(error.response)) {
                            modalConfig['error'] = error.response.data.detail;
                            this.props.show(MHT_EXIT_CONFIRM_MODAL, modalConfig);
                        }
                    });
            }
        };
        this.props.show(MHT_EXIT_CONFIRM_MODAL, modalConfig);
    };

    handleMHTJobFinish = (id, role) => {
        const {nextStatus, listName} = EMPLOYEE_PROCESS_MAP[role];
        const assignment = this.props.params.assignment;
        return MHTApi.finish(id, role, assignment, nextStatus)
            .then(response => {
                const normalized = normalize(camelizeKeys(response.data), jobSchema);
                this.props.merge(normalized.entities);
                this.props.setProcessed(assignment, true);
                this.props.removeItem(listName, normalized.result);
                this.props.show(EMPLOYEE_MHT_JOB_FINISH_MODAL);
                return Promise.resolve(true);
            });
    };

    tabHasFocus = () => {
        return (this.tabId === this.props.focusedTab);
    };

    registerKeyPressListener = () => {
        if (!this.keyListener) {
            document.addEventListener("keydown", this.onDocKeyPress);
            this.keyListener = true;
        }
    };

    unregisterKeyPressListener = () => {
        if (this.keyListener) {
            document.removeEventListener("keydown", this.onDocKeyPress);
            this.keyListener = false;
        }
    };

    onDocKeyPress = (event) => {

        const shiftKey = event.shiftKey;
        const ctrlKey = event.ctrlKey;
        const keyCode = event.keyCode;

        let newState = {};

        if (ctrlKey) {
            const canDo = (action) => MHTApi.userRoleCanDo(this.props.params.role, action);

            if (keyCode === 13 && shiftKey) {
                // Confirm (CTRL + SHIFT + ENTER)
                if (canDo('totra')) {
                    this.mutatePropertyOfSelectedSegments('is_totra');
                }
            } else if (keyCode === 13) {
                // Totra (CTRL + ENTER)
                if (canDo('confirm')) {
                    this.mutatePropertyOfSelectedSegments('status');
                }
            } else if (keyCode === 76 && shiftKey) {
                // Lock (CTRL + SHIFT + l)
                if (canDo('lock')) {
                    this.mutatePropertyOfSelectedSegments('is_locked');
                }
            } else if (keyCode === 68 && shiftKey) {
                // Lock (CTRL + SHIFT + d)
                if (canDo('copy')) {
                    this.mutatePropertyOfSelectedSegments('copy');
                }
            } else if (keyCode === 77 && shiftKey) {
                // Lock (CTRL + SHIFT + m)
                if (canDo('merge')) {
                    this.mergeSegments();
                }
            } else if (keyCode === 84) {
                // Focus terms panel (CTRL + t)
                this.panelTabsRef.current.setTab(0);
                if (shiftKey) {
                    // Create new term (CTRL + SHIFT + t)
                    newState.triggerTermModalModal = (this.state.triggerTermModalModal) ? this.state.triggerTermModalModal + 1 : 1;
                }
                // } else if (keyCode === 67) {
                //   // Focus comments panel (CTRL + c)
                //   this.panelTabsRef.current.setTab(1);
                //   if (shiftKey) {
                //     // Create new comment (CTRL + SHIFT + c)
                //     newState.triggerCommentModal = (this.state.triggerCommentModal) ? this.state.triggerCommentModal + 1 : 1;
                //   }
            } else if (keyCode === 82) {
                // Focus revisions panel (CTRL + r)
                this.panelTabsRef.current.setTab(2);
            }
        }

        if (shiftKey) {
            document.addEventListener("keyup", this.onDocKeyUp, false);
            newState.shiftKey = true;
        }

        if (
            (shiftKey || ctrlKey) &&
            Object.keys(newState)
        ) {
            this.setState(newState);
        }

    };

    onDocKeyUp = (event) => {
        const shiftKey = event.shiftKey;
        if (!shiftKey && this.state.shiftKey) {
            this.setState({
                shiftKey: false
            });
        }
        document.removeEventListener("keyup", this.onDocKeyUp, false);
    };

    loadComments = () => {
        return MHTApi
            .listAllComments(this.props.params.id)
            .then(response => {
                const items = response.data;
                let index = {};
                items.forEach(item => {
                    index[item.segment] = true;
                });
                return {
                    time: Date.now(),
                    index: index,
                    items: items
                };
            });
    };

    reLoadComments = () => {
        return this.loadComments()
            .then(response => {
                this.setState({
                    comments: response
                });
            });
    };

    onToolClick = (tool) => {

        const props = this.props;
        const state = this.state;
        const mhtId = props.params.id;

        switch (tool) {
            case 'editSource':
                this.setState({
                        editSource: !state.editSource
                    }
                );
                break;
            case 'split':
                // this.splitSegments();
                const prepareValue = this.splitSegmentsPrepare();
                this.triggerConfirmActionModal(
                    'Split Segment',
                    'Are you sure you want to split the selected segment?',
                    ()=> {
                        this.splitSegmentsRun(prepareValue.id, prepareValue.value);
                        this.closeConfirmActionModal();
                    }
                );
                break;
            case 'merge':
                // this.mergeSegments();
                this.triggerConfirmActionModal(
                    'Merge Segment',
                    'Are you sure you want to merge the selected segments?',
                    ()=> {
                        this.mergeSegments();
                        this.closeConfirmActionModal();
                    }
                );
                break;
            case 'lock':
                this.mutatePropertyOfSelectedSegments('is_locked');
                break;
            case 'totra':
                this.mutatePropertyOfSelectedSegments('is_totra');
                break;
            case 'confirm':
                this.mutatePropertyOfSelectedSegments('status');
                break;
            case 'copy':
                this.mutatePropertyOfSelectedSegments('copy');
                break;
            case 'stats':
                props.show(MHT_ANALYSIS_MODAL, {
                    id: mhtId,
                    service: props.params.service
                });
                break;
            case 'sourceFileDownload':
                props.sourcefileDownloadRequest(props.mht.document);
                break;
            case 'commentsDownload':
                this.checkSaveWarningModal(() => {
                    props.downloadCommentsRequest(
                        mhtId,
                        props.mht.document,
                    );
                });
                break;
            case 'targetFileDownload':
                this.setState({
                    openTargetFileDownloadModal: true,
                })
                /*
                this.checkSaveWarningModal(() => {
                    props.downloadTargetFileRequest(
                        mhtId,
                        props.mht.document,
                        props.params.service.dst.toLowerCase(),
                        "none",
                        undefined,
                        "none",
                        undefined
                    );
                });
                 */
                break;
            case 'bilingualFileDownloadExcel':
                this.checkSaveWarningModal(() => {
                    props.downloadBilingualExcelRequest(
                        mhtId,
                        props.params.role,
                        props.mht.document
                    );
                });
                break;
            case 'bilingualFileDownloadTmx':
                this.checkSaveWarningModal(() => {
                    props.downloadBilingualTmxRequest(
                        mhtId,
                        props.mht.document
                    );
                });
                break;
            case 'bilingualFileUpload':
                this.checkSaveWarningModal(() => {
                    this.openImportExcelModal();
                }, true);
                break;
            case 'analyzeFile':
                props.updateAnalysisRequest(mhtId);
                break;
            case 'preview':
                this.checkSaveWarningModal(() => {
                    props.showPreviewRequest(mhtId);
                });
                break;
            case 'preTranslate':
                this.triggerConfirmActionModal(
                    'Pre-Translate',
                    'Are you sure you want to run "Pre-Translate"?',
                    ()=> {
                        this.closeConfirmActionModal();
                        this.checkSaveWarningModal(() => {
                            this.openPreTranslateModal();
                        }, true);
                    }
                );
                break;
            case 'replaceTerms':
                this.triggerConfirmActionModal(
                    'Replace Term',
                    'Are you sure you want to run "Replace Term"?',
                    ()=> {
                        this.closeConfirmActionModal();
                        this.checkSaveWarningModal(() => {
                            this.reloadSegments(MHTApi[tool](mhtId));
                        }, true);
                    }
                );
                break;
            case 'postTranslate':
                this.triggerConfirmActionModal(
                    'Post-Translate',
                    'Are you sure you want to run "Post-Translate"?',
                    ()=> {
                        this.closeConfirmActionModal();
                        this.checkSaveWarningModal(() => {
                            this.reloadSegments(MHTApi[tool](mhtId));
                        }, true);
                    }
                );
                // this.checkSaveWarningModal(() => {
                //     this.reloadSegments(MHTApi[tool](mhtId));
                // }, true);
                break;
            case 'tmSettings':
                this.openMemorySettings();
                break;
            case 'termSettings':
                this.openTermSettings();
                break;
            case 'reCommitTm':
                this.checkSaveWarningModal(() => {
                    // props.reCommitRequest(mhtId);
                    this.setState({
                        reCommitTmModal: true,
                    })
                }, true);
                break;
            case 'machineTranslateSegments':
                this.openMachineTranslateModal();
                break;
            case 'finish':
                this.showFinishModalContent();
                break;
            default:
                console.log('onToolClick', tool);
        }

    };

    triggerConfirmActionModal = (title, message, callback) => {
        this.setState({
            warningModal: {
                isOpen: true,
                title: title,
                message: message,
                onConfirm: callback,
            }
        });
    };
    closeConfirmActionModal = () => {
        this.setState({
            warningModal: null
        });
    }

    mutatePropertyOfSelectedSegments = (property) => {

        const state = this.state;

        const userRole = this.props.params.role;
        const selectedItems = state.selectedSegment.items;

        let mutations = Object.assign({}, state.mutations);
        let firstValue;

        Object.keys(selectedItems).forEach((id, itemIndex) => {

            const segment = selectedItems[id];

            if (property === 'copy') {
                // copy source to target
                if (!this.pickSegmentProperty(segment, 'is_locked')) {
                    this.mutateSegmentProperty(segment, mutations, 'target_html', segment['source_html']);
                    this.mutateSegmentStatusAfterEdit(segment, mutations);
                }
            } else if (property === 'status') {
                if (
                    MHTApi.userRoleCanToggleSegmentStatus(
                        userRole,
                        this.pickSegmentProperty(segment, 'is_locked'),
                        this.pickSegmentProperty(segment, 'is_totra')
                    )
                ) {
                    const mutatedStatus = this.pickSegmentProperty(segment, property);
                    const newStatus = MHTApi.toggleSegmentStatus(userRole, mutatedStatus);
                    this.mutateSegmentProperty(segment, mutations, property, newStatus);
                }
            } else {

                if (!itemIndex) {
                    firstValue = !(
                        (typeof this.pickMutatedSegmentProperty(segment, property) !== 'undefined')
                            ? this.pickMutatedSegmentProperty(segment, property)
                            : segment[property]
                    );
                }

                this.mutateSegmentProperty(
                    segment,
                    mutations,
                    property,
                    firstValue
                );

            }

        });

        this.setState({
            mutations: mutations
        });

    };

    mutateSegmentStatusAfterEdit = (segment, mutations) => {

        const userRole = this.props.params.role;
        if (
            userRole !== MHTApi.userRoles.ae &&
            userRole !== MHTApi.userRoles.coordinator
        ) {
            const property = 'status';
            const mutatedStatus = this.pickSegmentProperty(segment, property);
            const newStatus = MHTApi.getSegmentStatusChanging(userRole, mutatedStatus);
            if (mutatedStatus !== newStatus) {
                this.mutateSegmentProperty(segment, mutations, property, newStatus);
            }
        }

    };

    segmentIsMutated = (segment, mutations) => {
        return !!(mutations[_mutationsIndex][segment.id]);
    };

    propertyIsMutated = (segment, mutations, property) => {
        return (
            this.segmentIsMutated(segment, mutations) &&
            typeof mutations[_mutationsData][segment.id][_mutationNew][property] !== 'undefined'
        );
    };

    mutateSegmentProperty = (segment, mutations, property, value) => {

        const id = segment.id;

        let indexTime = 0;

        if (
            segment[property] === value
        ) {
            if (
                this.propertyIsMutated(segment, mutations, property)
            ) {
                indexTime = Date.now();
                delete mutations[_mutationsData][id][_mutationNew][property];
                delete mutations[_mutationsData][id][_mutationOld][property];
                if (Object.keys(mutations[_mutationsData][id][_mutationNew]).length) {
                    mutations[_mutationsIndex][id] = indexTime;
                } else {
                    delete mutations[_mutationsData][id];
                    delete mutations[_mutationsIndex][id];
                }
            }
        } else {
            if (
                !this.segmentIsMutated(segment, mutations)
            ) {
                mutations[_mutationsData][id] = {
                    [_mutationOld]: {},
                    [_mutationNew]: {}
                };
            }
            indexTime = Date.now();
            mutations[_mutationsData][id][_mutationNew][property] = value;
            mutations[_mutationsData][id][_mutationOld][property] = segment[property];
            mutations[_mutationsIndex][id] = indexTime;
        }

        if (indexTime) {
            mutations.time = indexTime;
            mutations.count = Object.keys(mutations[_mutationsData]).length;
        }

    };

    pickMutatedSegmentProperty = (segment, property) => {

        const mutations = this.state.mutations;
        const id = segment.id;
        return (
            mutations[_mutationsData] &&
            mutations[_mutationsData][id] &&
            mutations[_mutationsData][id][_mutationNew] &&
            typeof mutations[_mutationsData][id][_mutationNew][property] !== 'undefined'
        ) ? mutations[_mutationsData][id][_mutationNew][property] : undefined;

    };

    onMemory = (event) => {

        const selectedSegment = this.state.selectedSegment;
        const segment = selectedSegment.items[selectedSegment.item];

        this.mutateSegmentText(
            '<p><span>' + plainTextToHtml(event.data.target || '') + '</span></p>',
            segment
        );

    };

    mutateSegmentText = (value, segment, isSource, callback) => {

        const state = this.state;
        const property = (isSource) ? 'source_html' : 'target_html';
        let mutations = Object.assign({}, state.mutations);

        this.mutateSegmentProperty(segment, mutations, property, value);
        this.mutateSegmentStatusAfterEdit(segment, mutations);

        this.setState({
            mutations: mutations
        }, () => {
            if (typeof callback === 'function') {
                callback()
            }
        });

    };

    reloadSegments = (promise) => {

        this.setState({
            isLoading: true
        }, () => {
            promise
                .then(this.loadSegments)
                // .then(loadedSegments => {
                //   return delay(loadedSegments, 100).promise;
                // })
                .then(response => {
                    this.setState({
                        timeLoaded: Date.now(),
                        ...response,
                        isLoading: false,
                        selectedSegment: this.defaultSelectedSegment(),
                        selectedSource: this.defaultSelectedSource(),
                        filteredItems: this.filterSegments(undefined, undefined, response.items)
                    });
                })
                .catch(error => {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                });
        });

    };

    mergeSegments = () => {
        this.setState({
            isLoading: true
        }, () => {

            const selectedSegment = this.state.selectedSegment;
            const keys = ['target_html', 'source_html'];

            let countFound = 0;
            let countIndex = 0;

            let segmentIds = [];
            let payload = {};

            // we need the segments in order,
            // thus walk through all items
            while (countFound < selectedSegment.count || countIndex < this.state.items.count) {
                const id = this.state.items.all[countIndex];
                if (selectedSegment.items[id]) {
                    countFound += 1;
                    keys.forEach(key => {
                        const targetHtml = this.pickSegmentProperty(selectedSegment.items[id], key).trim();
                        const innerP = targetHtml.match(/^<p\b[^>]*>(.*?)<\/p>$/s);

                        let string = (payload[key]) ? payload[key] : '';
                        if (innerP && innerP[1]) {
                            string += innerP[1];
                        } else {
                            const innerDiv = targetHtml.match(/^<div\b[^>]*>(.*?)<\/div>$/s);
                            if (innerDiv && innerDiv[1]) {
                                string += innerDiv[1];
                            } else {
                                string += targetHtml;
                            }
                        }
                        payload[key] = string;
                    });
                    segmentIds.push(id);
                }
                countIndex += 1;
            }

            keys.forEach(key => {
                if (payload[key]) {
                    if (key === 'target_html') {
                        payload[key] = '<p>' + payload[key].replace(/<p[^>]*><\/p>/g, '') + '</p>';
                    } else {
                        payload[key] = '<p>' + payload[key] + '</p>';
                    }
                }
            });

            this.reloadSegments(MHTApi.mergeSegments(segmentIds, payload).then(this.reLoadComments));

        });
    };

    // splitSegments = () => {
    //     const selectedSource = this.state.selectedSource;
    //     if (selectedSource.hasFocus && !selectedSource.hasSelection) {
    //
    //         const oldValue = selectedSource.editor.getData();
    //         selectedSource.editor.execute('enter');
    //
    //         const newValue = selectedSource.editor.getData();
    //         selectedSource.editor.setData(oldValue);
    //
    //         this.reloadSegments(MHTApi.splitSegments(
    //                 selectedSource.segmentId,
    //                 newValue.split('</p>').filter(value => !!value).map(value => value + '</p>')
    //             ).then(this.reLoadComments)
    //         );
    //
    //     }
    // };

    splitSegmentsPrepare = () => {
        const selectedSource = this.state.selectedSource;
        let payload = null;
        if (selectedSource.hasFocus && !selectedSource.hasSelection) {

            const oldValue = selectedSource.editor.getData();
            selectedSource.editor.execute('enter');

            const newValue = selectedSource.editor.getData();
            selectedSource.editor.setData(oldValue);

            payload = {
                id: selectedSource.segmentId,
                value: newValue.split('</p>').filter(value => !!value).map(value => value + '</p>')
            }
        }
        return payload;
    };
    splitSegmentsRun = (segmentId,string) => {
        if (segmentId && string) {
            this.reloadSegments(MHTApi.splitSegments(
                    segmentId,
                    string
                ).then(this.reLoadComments)
            );
        }
    };


    onComment = (event) => {

        const oldComments = this.state.comments;
        const oldIndex = oldComments.index[event.segment] || 0;

        let newComments = {
            time: Date.now()
        };

        if (event.name === 'created') {
            newComments = Object.assign({}, oldComments);
            newComments.index[event.segment] = oldIndex + 1;
            newComments.items.push(event.comment);
        } else if (event.name === 'updated') {
            newComments.index = Object.assign({}, oldComments.index);
            newComments.items = oldComments.items.map(item => {
                if (item.id === event.comment.id) {
                    return event.comment;
                } else {
                    return item;
                }
            });
        } else if (event.name === 'deleted') {
            newComments.index = Object.assign({}, oldComments.index);
            if (oldIndex) {
                newComments.index[event.segment] = oldIndex - 1;
            } else {
                delete newComments.index[event.segment];
            }
            newComments.items = oldComments.items.filter(item => {
                return (item.id !== event.comment.id);
            });
        }

        this.setState({
            timeLoaded: Date.now(),
            comments: newComments
        });

    };

    pickSegmentProperty = (segment, prop) => {
        const mutatedValue = this.pickMutatedSegmentProperty(segment, prop);
        return (typeof mutatedValue !== 'undefined') ? mutatedValue : segment[prop];
    };

    openMemorySettings = () => {
        this.setState({
            openMemorySettingsModal: true
        });
    };

    onMemorySettingsCancel = () => {
        this.setState({
            openMemorySettingsModal: false
        });
    };
    onMemorySettingsConfirm = (response) => {
        this.setState({
            timeLoaded: Date.now(),
            mht: response,
            openMemorySettingsModal: false
        });
    };

    openTermSettings = () => {
        this.setState({
            openTermSettingsModal: true
        });
    };
    onTermSettingsCancel = () => {
        this.setState({
            openTermSettingsModal: false
        });
    };
    onTermSettingsConfirm = (response) => {
        this.setState({
            timeLoaded: Date.now(),
            mht: response,
            openTermSettingsModal: false
        });
    };

    openImportExcelModal = () => {
        this.setState({
            openImportExcelModal: true
        });
    };
    onImportExcelModalCancel = () => {
        this.setState({
            openImportExcelModal: false
        });
    };
    onImportExcelModalConfirm = () => {
        const promise = delay('', 0).promise;
        this.reloadSegments(promise);
        this.setState({
            openImportExcelModal: false
        });
    };

    openPreTranslateModal = () => {
        this.setState({
            openPreTranslateModal: true
        });
    };
    onPreTranslateModalCancel = () => {
        this.setState({
            openPreTranslateModal: false
        });
    };
    onPreTranslateModalConfirm = () => {
        const promise = delay('', 0).promise;
        this.reloadSegments(promise);
        this.setState({
            openPreTranslateModal: false
        });
    };

    openMachineTranslateModal = () => {
        this.setState({
            openMachineTranslateModal: true
        });
    };
    onMachineTranslateModalCancel = () => {
        this.setState({
            openMachineTranslateModal: false
        });
    };
    onMachineTranslateModalConfirm = (machineTranslatedSegments) => {
        const selectedSegments = this.state.selectedSegment.items;
        machineTranslatedSegments
            .forEach(item => {
                this.mutateSegmentText(item.target_html, selectedSegments[item.id]);
            });
        this.setState({
            openMachineTranslateModal: false
        });
    };

    checkSaveWarningModal = (callback, isLost) => {
        if (this.state.mutations.count) {
            this.setState({
                openSaveWarningModal: callback,
                saveWarningModalType: (isLost) ? 'lost' : 'ignored'
            });
        } else {
            callback();
        }
    };
    onSaveWarningModalConfirm = () => {
        const nextAction = this.state.openSaveWarningModal;
        return this.saveSegments().then(() => {
            this.setState({
                openSaveWarningModal: false
            }, () => {
                nextAction();
            });
            return true;
        });
    };
    onSaveWarningModalDiscard = () => {
        const nextAction = this.state.openSaveWarningModal;
        this.setState({
            openSaveWarningModal: false
        }, () => {
            nextAction();
        });
    };
    onSaveWarningModalCancel = () => {
        this.setState({
            openSaveWarningModal: false
        });
    };

    onTermUpdate = () => {
        this.props.retrieveRequest(this.props.params.id);
    };

    onRowSelect = (
        event, segment, rowIndex
    ) => {

        const state = this.state;
        const selected = state.selectedSegment;
        const selectedItems = selected.items;
        const shiftKey = event.shiftKey;
        const metaKey = event.metaKey; // command/windows (meta) key
        const ctrlKey = event.ctrlKey;
        const segmentId = segment.id;

        let items = {};
        let itemsCount = 0;
        let lastRowIndex = rowIndex;

        if (shiftKey) {

            const previousIndex = selected.lastRowIndex;
            const currentIndex = rowIndex;

            // collect segments to select
            if (typeof previousIndex !== 'undefined') {

                lastRowIndex = selected.lastRowIndex;

                const listItems = (state.timeSearched + state.timeFiltered) ? state.filteredItems.all : state.items.all;

                listItems.forEach((segmentId, i) => {

                    const itemIndex = i + 1;

                    if (previousIndex < currentIndex) {
                        if (
                            itemIndex >= previousIndex &&
                            itemIndex <= currentIndex
                        ) {
                            items[segmentId] = state.items.data[segmentId];
                        }
                    } else {
                        if (
                            itemIndex <= previousIndex &&
                            itemIndex >= currentIndex
                        ) {
                            items[segmentId] = state.items.data[segmentId];
                        }
                    }
                });

            }

        } else if (metaKey || ctrlKey) {

            Object.keys(selectedItems).forEach(key => {
                if (key !== segmentId) {
                    items[key] = selectedItems[key];
                }
            });

        }

        if (!selectedItems[segmentId]) {
            items[segmentId] = segment;
        }

        // items count
        Object.keys(items).forEach(() => {
            itemsCount += 1;
        });

        this.setState({
            selectedSegment: {
                time: Date.now(),
                item: (itemsCount) ? segmentId : '',
                items: items,
                count: itemsCount,
                // firstRowIndex: lastRowIndex,
                lastRowIndex: lastRowIndex
            }
        });

    };

    onSourceFocus = (event, segment, editor) => {
        this.setState({
            selectedSegment: this.defaultSelectedSegment(segment),
            selectedSource: {
                hasFocus: true,
                segmentId: segment.id,
                editor: editor,
            }
        });
    };

    onTargetFocus = (segment) => {
        this.setState({
            selectedSegment: this.defaultSelectedSegment(segment)
        });
    };

    onSourceBlur = (event, segment) => {
        setTimeout(() => {
            if (this.state.selectedSource.segmentId === segment.id) {
                this.setState({
                    selectedSource: this.defaultSelectedSource()
                });
            }
        }, 500);
    };

    onSourceChange = (value, segment, callback) => {
        this.mutateSegmentText(value, segment, true, callback)
    };
    onTargetChange = (value, segment, callback) => {
        this.mutateSegmentText(value, segment, false, callback)
    };

    getSelectedSegment = () => {
        const state = this.state.selectedSegment;
        let selectedSegment = {};
        if (state.item) {
            const lastSegment = state.items[state.item];
            selectedSegment = {
                ...lastSegment,
                source_html: this.pickSegmentProperty(lastSegment, 'source_html'),
                source_text: convertHtmlToText(this.pickSegmentProperty(lastSegment, 'source_html')),
                target_html: this.pickSegmentProperty(lastSegment, 'target_html'),
                target_text: convertHtmlToText(this.pickSegmentProperty(lastSegment, 'target_html')),
            };
        }
        return selectedSegment
    }

    onHighlightRequest = (highlight, requestScroll) => {

        this.setState({
            highlight: highlight
        }, () => {
            if (
                requestScroll
            ) {
                const id = (requestScroll === 'top') ? this.state.items.all[0] : highlight.activeSegment;
                this.scrollToSegment(id);
            }
        });
    }

    scrollToSegment = (id) => {
        //element which needs to be scrolled to
        setTimeout(function () {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            } else {
                console.warn('scrollToSegment - not found', id);
            }
        }, 100);
    }

    scrollToSelectedSegment = () => {
        const selectedSegment = this.state.selectedSegment;
        if (selectedSegment.item) {
            this.scrollToSegment(selectedSegment.item)
        }
    }

    render() {
        const state = this.state;
        const props = this.props;
        const params = props.params;
        const userRole = params.role;
        const fileStored = params.stored;

        const mutations = state.mutations;

        const mhtPropIsReady = (props && typeof props.mht !== 'undefined');
        const isLoading = state.isLoading;

        const sourceLanguage = params.service.src;
        const targetLanguage = params.service.dst;

        const lockedSelectionCount = () => {
            let c = 0;
            if (state.selectedSegment.count) {
                Object.keys(state.selectedSegment.items).forEach(id => {
                    if (this.pickSegmentProperty(state.selectedSegment.items[id], 'is_locked')) {
                        c += 1;
                    }
                });
            }
            return c;
        }

        return (
            <React.Fragment>
                <TaPane size={'page'}>
                    <TaMhtEditorToolbar
                        status={params.status}
                        className={editorStyles.page__tools}
                        userRole={userRole}
                        fileStored={fileStored}
                        selectionCount={state.selectedSegment.count}
                        lockedSelectionCount={lockedSelectionCount()}
                        mutationCount={mutations.count}
                        isEditSource={!!state.editSource}
                        isSaving={state.isSaving}
                        sourceHasFocus={state.selectedSource.hasFocus}
                        sourceHasSelection={state.selectedSource.hasSelection}
                        onToolClick={this.onToolClick}
                        saveSegments={this.saveSegments}
                    />
                    <div
                        className={editorStyles.body}
                    >
                        <div
                            className={editorStyles.body__main}
                        >
                            <TaCard
                                flex={'auto'}
                                className={(state.shiftKey) ? editorStyles.shiftKey : null}
                            >
                                <TaMhtEditorWidgets

                                    mhtId={params.id}
                                    targetLanguage={targetLanguage}

                                    segments={state.items}
                                    search={state.search}
                                    filter={state.filter}
                                    highlight={state.highlight}

                                    defaultSearch={this.defaultSearch}

                                    onSearchRequest={this.onSearchRequest}
                                    onFilterRequest={this.onFilterRequest}
                                    onHighlightRequest={this.onHighlightRequest}
                                    onReplaceRequest={this.onTargetChange}
                                    onResetFilterRequest={this.onResetFilterWidgetRequest}

                                    pickSegmentProperty={this.pickSegmentProperty}
                                    pickMutatedSegmentProperty={this.pickMutatedSegmentProperty}

                                    checkSaveWarningModal={this.checkSaveWarningModal}
                                />
                                <TaMhtEditorSegments
                                    userRole={userRole}
                                    sourceLanguage={sourceLanguage}
                                    targetLanguage={targetLanguage}
                                    items={state.items}
                                    filteredItems={state.filteredItems}
                                    itemsChunkSize={this.itemsChunkSize}
                                    mutations={mutations}
                                    comments={state.comments}
                                    highlight={state.highlight}

                                    isLoading={isLoading}
                                    isEditSourceEnabled={!!state.editSource}

                                    timeLoaded={state.timeLoaded}
                                    timeMutated={mutations.time}
                                    timeSearched={state.timeSearched}
                                    timeFiltered={state.timeFiltered}
                                    timeCommented={state.comments.time}

                                    selectedSegment={state.selectedSegment}

                                    pickSegmentProperty={this.pickSegmentProperty}

                                    onSourceFocus={this.onSourceFocus}
                                    onSourceBlur={this.onSourceBlur}
                                    onSourceChange={this.onSourceChange}
                                    onTargetFocus={this.onTargetFocus}
                                    onTargetChange={this.onTargetChange}
                                    onRowSelect={this.onRowSelect}
                                />
                            </TaCard>
                        </div>
                        <div
                            className={editorStyles.body__side}
                        >
                            <TaCard
                                flex={'auto'}
                            >
                                {
                                    (mhtPropIsReady) && (
                                        <React.Fragment>
                                            <TaTabs
                                                flex={'auto'}
                                                spacing={'compact'}
                                                background={'grey'}
                                                ref={this.panelTabsRef}
                                            >
                                                <TaTab label={'Terms'}>
                                                    <TaMhtEditorTerms
                                                        mhtId={params.id}
                                                        segmentId={state.selectedSegment.item}
                                                        userRole={userRole}
                                                        source={sourceLanguage}
                                                        target={targetLanguage}
                                                        triggerModal={state.triggerTermModalModal}
                                                        onUpdate={this.onTermUpdate}
                                                        getSelectedSegment={this.getSelectedSegment}
                                                    />
                                                </TaTab>
                                                <TaTab label={'Comments'}>
                                                    <TaMhtEditorComments
                                                        mhtId={params.id}
                                                        segmentId={state.selectedSegment.item}
                                                        comments={state.comments.items}
                                                        onEvent={(event) => this.onComment(event)}
                                                        triggerModal={state.triggerCommentModal}
                                                    />
                                                </TaTab>
                                                <TaTab label={'Revisions'}>
                                                    <TaMhtEditorRevisions
                                                        mhtId={params.id}
                                                        segmentId={state.selectedSegment.item}
                                                    />
                                                </TaTab>
                                            </TaTabs>
                                        </React.Fragment>
                                    )
                                }
                            </TaCard>
                            <TaCard
                                flex={'auto'}
                            >
                                <TaTabs
                                    flex={'auto'}
                                    spacing={'compact'}
                                    background={'grey'}
                                >
                                    <TaTab
                                        label={'Translation Suggestions'}
                                    >
                                        <TaMhtEditorMemories
                                            mhtId={params.id}
                                            segmentId={state.selectedSegment.item}
                                            source={sourceLanguage}
                                            target={targetLanguage}
                                            onEvent={(event) => this.onMemory(event)}
                                        />
                                    </TaTab>
                                </TaTabs>
                            </TaCard>
                        </div>

                    </div>
                </TaPane>
                {
                    (mhtPropIsReady) && (
                        <React.Fragment>
                            <TaMhtEditorSettingsMemory
                                mht={props.normalizedMHT}
                                isOpen={state.openMemorySettingsModal}
                                onCancel={this.onMemorySettingsCancel}
                                onConfirm={this.onMemorySettingsConfirm}
                            />
                            <TaMhtEditorSettingsTerm
                                mht={props.normalizedMHT}
                                client={params.client}
                                isOpen={state.openTermSettingsModal}
                                onCancel={this.onTermSettingsCancel}
                                onConfirm={this.onTermSettingsConfirm}
                            />
                            <TaMhtEditorImportExcelModal
                                mhtId={params.id}
                                isOpen={state.openImportExcelModal}
                                onCancel={this.onImportExcelModalCancel}
                                onConfirm={this.onImportExcelModalConfirm}
                            />
                            <TaMhtEditorPreTranslateModal
                                mhtId={params.id}
                                isOpen={state.openPreTranslateModal}
                                onCancel={this.onPreTranslateModalCancel}
                                onConfirm={this.onPreTranslateModalConfirm}
                            />
                            <TaMhtEditorMachineTranslateModal
                                mhtId={params.id}
                                selectedSegment={state.selectedSegment}
                                isOpen={state.openMachineTranslateModal}
                                onCancel={this.onMachineTranslateModalCancel}
                                onConfirm={this.onMachineTranslateModalConfirm}
                            />
                            <TaMhtEditorSaveWarningModal
                                isOpen={typeof state.openSaveWarningModal === 'function'}
                                onCancel={this.onSaveWarningModalCancel}
                                onConfirm={this.onSaveWarningModalConfirm}
                                onDiscard={this.onSaveWarningModalDiscard}
                            >
                                {
                                    (state.saveWarningModalType === 'lost') ? (
                                        <p>Would you like to save changes to this document before proceeding?<br/>
                                            Your unsaved changes will be lost if you don't save them.</p>
                                    ) : (
                                        <p>Would you like to save changes to this document before proceeding?<br/>
                                            Your unsaved changes will not be included if you don't save them.</p>
                                    )
                                }
                            </TaMhtEditorSaveWarningModal>
                            <TaMhtEditorConfirmActionModal
                                isOpen={ state.warningModal ? state.warningModal.isOpen : false }
                                title={ state.warningModal ? state.warningModal.title : '' }
                                message={ state.warningModal ? state.warningModal.message : '' }
                                onConfirm={ state.warningModal ? state.warningModal.onConfirm : undefined }
                                onCancel={this.closeConfirmActionModal}
                            />
                            <TaMhtEditorDownloadTargetFileModal
                                isOpen={state.openTargetFileDownloadModal}
                                onConfirm={(e)=> {
                                    props.downloadTargetFileRequest(
                                        props.params.id,
                                        props.mht.document,
                                        props.params.service.dst.toLowerCase(),
                                        e.font_size === "auto" ? "auto" :  (e.font_size === "default" || !e.font_size) ? "none": "static",
                                        e.font_size && e.font_size !== "default" && e.font_size !== "auto" ? e.font_size:  undefined,
                                        (e.font_family === "default" || !e.font_family) ? "none": "static",
                                        e.font_family && e.font_family !== "default" ? e.font_family:  undefined,
                                        e.restore_style && e.restore_style !== "default" ? e.restore_style: "none",
                                    );
                                    this.setState({
                                        openTargetFileDownloadModal: false,
                                    });
                                }}
                                onCancel={()=> this.setState({
                                    openTargetFileDownloadModal: false,
                                })}
                            />
                            <TaMhtEditorReCommitTmModal
                                isOpen={state.reCommitTmModal}
                                mhtId={params.id}
                                onDone={()=> this.setState({
                                    reCommitTmModal: false,
                                })}
                                onCancel={()=> this.setState({
                                    reCommitTmModal: false,
                                })}
                            />
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.params.id;
    const mht = getMHTValueById(state, id);
    return {
        mht: mht,
        normalizedMHT: getMHTById(state, id),
        focusedTab: getCurrentTab(state),
        isConfirmOpen: isMHTExitConfirmModalOpen(state),
        isConfirmRequiredTab: isConfirmRequiredTab(state)
    };
};

const mapDispatchToProps = (disptach) => {
    return bindActionCreators({
        show,
        setConfirmRequire,
        closeTab,
        retrieveRequest,
        merge,
        setProcessed,
        removeItem,
        sourcefileDownloadRequest,
        downloadCommentsRequest,
        downloadTargetFileRequest,
        downloadBilingualExcelRequest,
        downloadBilingualTmxRequest,
        showPreviewRequest,
        updateAnalysisRequest,
        reCommitRequest
    }, disptach);
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MHTEditor);
