import moment from 'moment';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { getJobEntities, getAssignmentEntities, getUserEntities, getCountryEntities } from 'selectors/entities';
import { getMyGroups, getMyUserId, isAE } from 'selectors/auth';
import { getUserById, getProfileByUserId } from 'selectors/users';
import { getClientById } from 'selectors/clients';
import { getCustomerValueById } from 'selectors/customers';
import { getTemplateById } from 'selectors/templates';
import { getJobById, getJobScheduleQuickEdit } from 'selectors/jobs';
import { PAYMENT_SEARCH_FORM } from 'misc/constants';


// Payment Search ==============================================================
const getPaymentSearchFromSelector = formValueSelector(PAYMENT_SEARCH_FORM);
export const getPaymentSearchRole = state => getPaymentSearchFromSelector(state, 'role');
export const getPaymentSearchFormInitialValues = () => {
    const initialValues = {}
    const today = moment();
    initialValues['start'] = today.clone().subtract(1, 'months').format('YYYY-MM-DD');
    initialValues['end'] = today.format('YYYY-MM-DD');
    initialValues['role'] = 1;
    return initialValues
}

// Job Search Form initial values ==============================================
export const getJobSearchFormInitialValues = () => {
  const initialValues = {}
  const today = moment();
  initialValues['start'] = today.clone().subtract(1, 'months').format('YYYY-MM-DD');
  initialValues['end'] = today.format('YYYY-MM-DD');
  return initialValues
}

// Job History Form initial values =============================================
export const getJobHistoryFormInitialValues = createSelector(
  [getMyGroups],
  (myGroups) => {
    const defaultGroup = myGroups[0];
    const today = moment();
    const initialValues = {};
    initialValues['role'] = defaultGroup ? defaultGroup.id : null;
    initialValues['start'] = today.clone().subtract(2, 'week').format('YYYY-MM-DD');
    initialValues['end'] = today.format('YYYY-MM-DD');
    return initialValues;
  }
);

export const getUserAccountFormInitialValues = createSelector(
  [getUserById],
  (user) => {
    const initialValues = { ...user };
    return initialValues;
  }
)


export const getUserProfileFormInitialValues = createSelector(
  [getProfileByUserId],
  (profile) => {
    const initialValues = { ...profile };
    return initialValues;
  }
)

export const getClientEditFormInitialValues = createSelector(
  [getClientById], (client) => {
    const initialValues = { ...client };
    return initialValues;
  }
)

// Job Create Form initial values ==============================================
export const getJobCreateFormInitialValues = createSelector(
  [getMyUserId],
  (myUserId) => {
    const initialValues = {};
    initialValues['receiveDate'] = moment().format('YYYY-MM-DD');
    initialValues['additionalCount'] = 0;
    initialValues['ae'] = myUserId;
    return initialValues;
  }
)

// Job Duplicate Form initial values ===========================================
export const getJobDuplicateFormInitialValuesById = createSelector(
  [getJobById, getUserEntities],
  (job, userEntities) => {
    const aeExists = job.ae ? userEntities[job.ae].isActive : false;
    const ae = aeExists ? job.ae : null;
    const initialValues = {}
    initialValues['client'] = job.client;
    initialValues['receiveDate'] = moment().format('YYYY-MM-DD');
    initialValues['industry'] = job.industry;
    initialValues['field'] = job.field;
    initialValues['ae'] = ae;
    initialValues['instruction'] = job.instruction;
    initialValues['notes'] = job.notes;
    initialValues['edinet'] = job.edinet;
    initialValues['ifrs'] = job.ifrs;
    return initialValues
  }
)

export const getJobCreateFormInitialValuesByTemplateId = createSelector(
  [getTemplateById],
  (template) => {
    const initialValues = {};
    initialValues['type'] = template.type;
    initialValues['aeSubject'] = template.aeSubject;
    initialValues['subject'] = template.subject;
    initialValues['level'] = template.level;
    initialValues['client'] = template.client;
    initialValues['receiveDate'] = moment().format('YYYY-MM-DD');
    initialValues['industry'] = template.industry;
    initialValues['field'] = template.field;
    initialValues['additionalCount'] = 0;
    initialValues['ae'] = template.ae;
    initialValues['instruction'] = template.instruction;
    initialValues['notes'] = template.notes;
    initialValues['requireTotra'] = template.requireTotra;
    initialValues['requireDtp'] = template.requireDtp;
    initialValues['strictDeadline'] = template.strictDeadline;
    initialValues['futureJob'] = template.futureJob;
    initialValues['isUrgent'] = template.isUrgent;
    initialValues['edinet'] = template.edinet;
    initialValues['ifrs'] = template.ifrs;
    return initialValues;
  }
)

// Split Form initial values ===================================================
export const getJobSplitFormInitialValuesById = createSelector(
  [getJobById, getUserEntities],
  (job, userEntities) => {
    const aeExists = job.ae ? userEntities[job.ae].isActive : false;
    const ae = aeExists ? job.ae : null;
    const initialValues = {}
    initialValues['type'] = job.type;
    initialValues['baseNumber'] = job.baseNumber;
    initialValues['branch'] = job.branch;
    initialValues['aeSubject'] = job.aeSubject;
    initialValues['subject'] = job.subject;
    initialValues['client'] = job.client;
    initialValues['receiveDate'] = moment().format('YYYY-MM-DD');
    initialValues['industry'] = job.industry;
    initialValues['field'] = job.field;
    initialValues['ae'] = ae;
    initialValues['coordinator'] = job.coordinator;
    initialValues['instruction'] = job.instruction;
    initialValues['notes'] = job.notes;
    initialValues['edinet'] = job.edinet;
    initialValues['ifrs'] = job.ifrs;
    return initialValues;
  }
)

export const getJobIssueFormInitialValuesById = createSelector(
  [getJobById],
  (job) => {
    const initialValues = {}
    initialValues['type'] = job.type;
    initialValues['baseNumber'] = job.baseNumber;
    initialValues['branch'] = job.branch;
    initialValues['aeSubject'] = job.aeSubject;
    initialValues['subject'] = job.subject;
    initialValues['memo'] = job.memo;
    initialValues['service'] = job.service;
    initialValues['level'] = job.level;
    initialValues['client'] = job.client;
    initialValues['receiveDate'] = job.receiveDate;
    initialValues['deadlineDate'] = job.deadlineDate;
    initialValues['deadlineTime'] = job.deadlineTime;
    initialValues['industry'] = job.industry;
    initialValues['field'] = job.field;
    initialValues['sourceCount'] = job.sourceCount;
    initialValues['targetCount'] = job.targetCount;
    initialValues['additionalCount'] = job.additionalCount;
    initialValues['quotation'] = job.quotation;
    initialValues['ae'] = job.ae;
    initialValues['coordinator'] = job.coordinator;
    initialValues['instruction'] = job.instruction;
    initialValues['notes'] = job.notes;
    initialValues['requireTotra'] = job.requireTotra;
    initialValues['requireDtp'] = job.requireDtp;
    initialValues['strictDeadline'] = job.strictDeadline;
    initialValues['futureJob'] = job.futureJob;
    initialValues['isUrgent'] = job.isUrgent;
    initialValues['edinet'] = job.edinet;
    initialValues['ifrs'] = job.ifrs;
    return initialValues;
  }
)

export const getJobSplitIssueFormInitialValuesById = createSelector(
  [getJobById, getAssignmentEntities, getUserEntities],
  (job, assignmentEntities, userEntities) => {
    const aeExists = job.ae ? userEntities[job.ae].isActive : false;
    const ae = aeExists ? job.ae : null;
    const initialValues = {}

    const totra = assignmentEntities[job.totra];
    const translator = assignmentEntities[job.translator];
    const editor = assignmentEntities[job.editor];
    const checker = assignmentEntities[job.checker];
    const finalEditor = assignmentEntities[job.finalEditor];
    const secondFinalEditor = assignmentEntities[job.secondFinalEditor];
    const dtp = assignmentEntities[job.dtp];
    const secondDtp = assignmentEntities[job.secondDtp]

    initialValues['type'] = job.type;
    initialValues['baseNumber'] = job.baseNumber;
    initialValues['branch'] = job.branch;
    initialValues['aeSubject'] = job.aeSubject;
    initialValues['subject'] = job.subject;
    initialValues['memo'] = job.memo;
    initialValues['service'] = job.service;
    initialValues['level'] = job.level;
    initialValues['client'] = job.client;
    initialValues['receiveDate'] = job.receiveDate;
    initialValues['deadlineDate'] = job.deadlineDate;
    initialValues['deadlineTime'] = job.deadlineTime;
    initialValues['industry'] = job.industry;
    initialValues['field'] = job.field;
    initialValues['sourceCount'] = job.sourceCount;
    initialValues['targetCount'] = job.targetCount;
    initialValues['additionalCount'] = job.additionalCount;
    initialValues['additionalCountRoles'] = job.additionalCountRoles;
    initialValues['ae'] = ae;
    initialValues['coordinator'] = job.coordinator;
    initialValues['edinet'] = job.edinet;
    initialValues['ifrs'] = job.ifrs;
    initialValues['instruction'] = job.instruction;
    initialValues['notes'] = job.notes;
    initialValues['totra'] = totra;
    initialValues['translator'] = translator;
    initialValues['editor'] = editor;
    initialValues['checker'] = checker;
    initialValues['finalEditor'] = finalEditor;
    initialValues['secondFinalEditor'] = secondFinalEditor;
    initialValues['dtp'] = dtp;
    initialValues['secondDtp'] = secondDtp;

    return initialValues;
  }
)

export const getJobEditFormInitialValuesById = createSelector(
  [getJobById, getAssignmentEntities],
  (job, assignmentEntities) => {
    const initialValues = {}
    const totra = assignmentEntities[job.totra];
    const translator = assignmentEntities[job.translator];
    const editor = assignmentEntities[job.editor];
    const checker = assignmentEntities[job.checker];
    const finalEditor = assignmentEntities[job.finalEditor];
    const secondFinalEditor = assignmentEntities[job.secondFinalEditor];
    const dtp = assignmentEntities[job.dtp];
    const secondDtp = assignmentEntities[job.secondDtp]

    initialValues['type'] = job.type;
    initialValues['baseNumber'] = job.baseNumber;
    initialValues['branch'] = job.branch;
    initialValues['aeSubject'] = job.aeSubject;
    initialValues['subject'] = job.subject;
    initialValues['memo'] = job.memo;
    initialValues['service'] = job.service;
    initialValues['level'] = job.level;
    initialValues['client'] = job.client;
    initialValues['receiveDate'] = job.receiveDate;
    initialValues['deadlineDate'] = job.deadlineDate;
    initialValues['deadlineTime'] = job.deadlineTime;
    initialValues['industry'] = job.industry;
    initialValues['field'] = job.field;
    initialValues['sourceCount'] = job.sourceCount;
    initialValues['targetCount'] = job.targetCount;
    initialValues['additionalCount'] = job.additionalCount;
    initialValues['additionalCountRoles'] = job.additionalCountRoles;
    initialValues['quotation'] = job.quotation;
    initialValues['ae'] = job.ae;
    initialValues['coordinator'] = job.coordinator;
    initialValues['instruction'] = job.instruction;
    initialValues['notes'] = job.notes;
    initialValues['requireTotra'] = job.requireTotra;
    initialValues['requireDtp'] = job.requireDtp;
    initialValues['strictDeadline'] = job.strictDeadline;
    initialValues['futureJob'] = job.futureJob;
    initialValues['isUrgent'] = job.isUrgent;
    initialValues['edinet'] = job.edinet;
    initialValues['ifrs'] = job.ifrs;
    
    initialValues['aeMemoryConfirmed'] = job.aeMemoryConfirmed;
    initialValues['coordinatorMemoryConfirmed'] = job.coordinatorMemoryConfirmed;

    initialValues['totra'] = totra;
    initialValues['translator'] = translator;
    initialValues['editor'] = editor;
    initialValues['checker'] = checker;
    initialValues['finalEditor'] = finalEditor;
    initialValues['secondFinalEditor'] = secondFinalEditor;
    initialValues['dtp'] = dtp;
    initialValues['secondDtp'] = secondDtp;

    return initialValues;
  }
)

export const getTemplateEditFormInitialValuesById = createSelector(
  [getTemplateById],
  (template) => {
    const initialValues = { ...template };
    return initialValues;
  }
)

export const getJobDraftEditFormInitialValuesById = createSelector(
  [getJobById],
  (job) => {
    const initialValues = { ...job };
    return initialValues;
  }
)

// Quick Edit ==================================================================
export const getJobQuikEditFormInitialValues = createSelector(
  [getJobScheduleQuickEdit, getJobEntities, getAssignmentEntities],
  (id, jobEntities, assignmentEntities) => {
    const job = jobEntities[id];
    const initialValues = {};
    if (job) {
      initialValues['type'] = job.type;
      initialValues['baseNumber'] = job.baseNumber;
      initialValues['number'] = job.number;
      initialValues['branch'] = job.branch;
      initialValues['subject'] = job.subject;
      initialValues['memo'] = job.memo;
      initialValues['service'] = job.service;
      initialValues['level'] = job.level;
      initialValues['client'] = job.client;
      initialValues['receiveDate'] = job.receiveDate;
      initialValues['deadlineDate'] = job.deadlineDate;
      initialValues['deadlineTime'] = job.deadlineTime;
      initialValues['industry'] = job.industry;
      initialValues['field'] = job.field;
      initialValues['sourceCount'] = job.sourceCount;
      initialValues['targetCount'] = job.targetCount;
      initialValues['additionalCount'] = job.additionalCount;
      initialValues['additionalCountRoles'] = job.additionalCountRoles;
      initialValues['quotation'] = job.quotation;
      initialValues['ae'] = job.ae;
      initialValues['coordinator'] = job.coordinator;
      initialValues['instruction'] = job.instruction;
      initialValues['notes'] = job.notes;
      initialValues['requireTotra'] = job.requireTotra;
      initialValues['requireDtp'] = job.requireDtp;
      initialValues['strictDeadline'] = job.strictDeadline;
      initialValues['futureJob'] = job.futureJob;
      initialValues['ae'] = job.ae;
      initialValues['coordinator'] = job.coordinator;
      initialValues['strictDeadline'] = job.strictDeadline;
      initialValues['totra'] = assignmentEntities[job.totra];
      initialValues['translator'] = assignmentEntities[job.translator];
      initialValues['editor'] = assignmentEntities[job.editor];
      initialValues['checker'] = assignmentEntities[job.checker];
      initialValues['finalEditor'] = assignmentEntities[job.finalEditor];
      initialValues['secondFinalEditor'] = assignmentEntities[job.secondFinalEditor];;
      initialValues['dtp'] = assignmentEntities[job.dtp];
      initialValues['secondDtp'] = assignmentEntities[job.secondDtp];
    }
    return initialValues
  }
)

export const getCustomerCreateFormInitialValues = createSelector(
  [getCountryEntities, getMyUserId, isAE], (countries, myUserId, isAE) => {
    const _countries = Object.values(countries).filter(c => c.name == "Japan")
    const initialValues = {}
    initialValues["country"] = (_countries.length > 0) ? _countries[0].id : null;
    initialValues["ae"] = isAE ? myUserId : null;
    initialValues["language"] = "ja-JP";
    return initialValues
  }
)


export const getCustomerEditFormInitialValues = createSelector(
  [getCustomerValueById], (customer) => {
    const initialValues = {
      username: customer.user.username,
      email: customer.user.email,
      country: customer.country.id,
      ae: customer.ae.id,
      language: customer.language,
      to: customer.to,
      client: customer.client.id,
      isActive: customer.user.isActive,
    };
    return initialValues;
  }
)
