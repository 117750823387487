import axios from 'axios';

const inArray = (item, itemsArray) => (itemsArray.indexOf(item) >= 0);

const userRoles = {
  coordinator: 'coordinator',
  totra: 'totra',
  translator: 'translator',
  checker: 'checker',
  editor: 'editor',
  finalEditor: 'finalEditor',
  secondFinalEditor: 'secondFinalEditor',
  dtp: 'dtp',
  secondDtp: 'secondDtp',
  ae: 'ae'
}
const confirmedRoleStatusMap = {
  [userRoles.ae]: 'Confirmed',
  [userRoles.coordinator]: 'Confirmed',
  [userRoles.totra]: 'Totrad',
  [userRoles.translator]: 'Translated',
  [userRoles.editor]: 'Edited',
  [userRoles.checker]: 'Checked',
  [userRoles.finalEditor]: 'Final Edited',
  [userRoles.secondFinalEditor]: 'Second Final Edited',
  [userRoles.dtp]: 'Dtped',
  [userRoles.secondDtp]: 'Second Dtped'
};
const changingRoleStatusMap = {
  [userRoles.ae]: 'Confirmed',
  [userRoles.coordinator]: 'Confirmed',
  [userRoles.totra]: 'Totraing',
  [userRoles.translator]: 'Translating',
  [userRoles.editor]: 'Editing',
  [userRoles.checker]: 'Checking',
  [userRoles.finalEditor]: 'Final Editing',
  [userRoles.secondFinalEditor]: 'Second Final Editing',
  [userRoles.dtp]: 'Dtping',
  [userRoles.secondDtp]: 'Second Dtping'
}
const MHTApi = {

  upload: (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('file', values.file);
    formData.append('job', values.job);

    formData.append('include_document_header', values.includeDocumentHeader);
    formData.append('include_document_footer', values.includeDocumentFooter);
    formData.append('include_document_comment', values.includeDocumentComment || false);
    formData.append('include_document_hyperlink', values.includeDocumentHyperlink);
    formData.append('include_document_alt_text', values.includeDocumentAltText || false);
    formData.append('include_document_embedded_sheet', values.includeDocumentEmbeddedSheet || false);

    formData.append('include_sheet_header', values.includeSheetHeader);
    formData.append('include_sheet_footer', values.includeSheetFooter);
    formData.append('include_sheet_comment', values.includeSheetComment || false);
    formData.append('include_sheet_hyperlink', values.includeSheetHyperlink);
    formData.append('include_sheet_alt_text', values.includeSheetAltText || false);
    formData.append('include_sheet_hidden_content', values.includeSheetHiddenContent || false);
    formData.append('include_sheet_name', values.includeSheetName);

    formData.append('include_slide', values.includeSlide);
    formData.append('include_slide_header', values.includeSlideHeader || false);
    formData.append('include_slide_footer', values.includeSlideFooter);
    formData.append('include_slide_comment', values.includeSlideComment || false);
    formData.append('include_slide_hyperlink', values.includeSlideHyperlink);
    formData.append('include_slide_alt_text', values.includeSlideAltText || false);
    formData.append('include_slide_hidden_content', values.includeSlideHiddenContent || false);
    formData.append('include_slide_notes', values.includeSlideNotes);
    formData.append('include_slide_master', values.includeSlideMaster || false);
    formData.append('include_slide_embedded_sheet', values.includeSlideEmbeddedSheet || false);

    formData.append('include_tentative', values.includeTentative);
    formData.append('search_method', values.searchMethod);
    formData.append('memory_suggest_ratio', values.memorySuggestRatio);
    formData.append('require_auto_lock', values.requireAutoLock);
    // formData.append('use_google_translate', values.useGoogleTranslate);
    formData.append('use_ai_translate', values.useAITranslate);
    formData.append('exclude_penalty', values.excludePenalty);

    if (values.referenceParentCategories) {
      values.referenceParentCategories.forEach(category => {
        formData.append('reference_parent_categories', category);
      });
    }
    if (values.referenceCategories) {
      values.referenceCategories.forEach(category => {
        formData.append('reference_categories', category);
      });
    }
    if (values.referenceWordlists) {
      values.referenceWordlists.forEach(wordlist => {
        formData.append('reference_wordlists', wordlist);
      });
    }
    if (values.referenceAdditionalWordlists) {
      values.referenceAdditionalWordlists.forEach(additional_wordlist => {
        formData.append('reference_additional_wordlists', additional_wordlist);
      });
    }
    if (values.referenceJobs) {
      values.referenceJobs.forEach(ref_job => {
        formData.append('reference_jobs', ref_job);
      });
    }
    if (values.referenceClients) {
      values.referenceClients.forEach(ref_client => {
        formData.append('reference_clients', ref_client);
      });
    }
    return axios.post(`mht/upload/`, formData);
  },
  retrieve: (id) => {
    return axios.get(`mht/${id}/`);
  },
  update: (id, data) => {
    return axios.patch(`mht/${id}/`, data);
  },

  retrieveAnalysis: (id) => {
    return axios.get(`mht/${id}/analysis/`);
  },
  updateAnalysis: (id) => {
    return axios.post(`mht/${id}/analysis/`);
  },
  reCommit: (id, data) => {
    return axios.post(`mht/${id}/recommit/`, (
        data &&
        data.register_date // YYYY-MM-DD
    ) ? data : {});
  },
  importExel: (id, data) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return axios.post(`mht/${id}/import/excel/`, formData);
  },

  getUnitCount: (values) => {
    return axios.post(`mht/count/`, values);
  },
  finish: (mhtId, userRole, assignment, nextStatus) => {
    const values = {
      assignment,
      role: userRole,
      segment_status: confirmedRoleStatusMap[userRole],
      job_status: nextStatus
    }
    return axios.post(`mht/${mhtId}/finish/`, values);
  },

  updateMemory: (mhtId) => {
    return axios.post(`mht/${mhtId}/memories/update/`);
  },
  confirmMemory: (mhtId, userRole) => {
    return axios.post(`mht/${mhtId}/confirm/`, {
      role: userRole,
      status: confirmedRoleStatusMap[userRole]
    });
  },
  preTranslate: (mhtId, values) => {
    return axios.post(`mht/${mhtId}/memories/pretranslate/`, values);
  },
  postTranslate: (mhtId) => {
    return axios.post(`mht/${mhtId}/memories/posttranslate/`, {
      include_tentative: true
    });
  },

  machineTranslateSegments: (mhtId, segments, options) => {
    return axios.post(`mht/${mhtId}/translate/`, {
      segments: segments,
      include_tentative: !!options.include_tentative,
      resources: options.resources
    });
  },

  addTerm: (mhtId, data) => {
    return axios.post(`mht/${mhtId}/terms/`, data);
  },
  replaceTerms: (mhtId) => {
    return axios.post(`mht/${mhtId}/terms/patch/`);
  },

  listPending: (params) => {
    return axios.get(`mht/pending/`, {params});
  },
  commitPending: (values) => {
    return axios.post(`mht/pending/commit/`, values);
  },
  rejectPending: (values) => {
    return axios.post(`mht/pending/reject/`, values);
  },

  analysisReport: (params) => {
    return axios.get(`mht/analysis/report/`, {params});
  },

  listSegments: (mhtId, params) => {
    console.log('listSegments - params', params)
    return axios.get(`mht/${mhtId}/segments/`, {params: params});
  },
  listAllSegments: (mhtId) => {
    return axios.get(`mht/${mhtId}/segments/all/`);
  },
  listAllComments: (mhtId) => {
    return axios.get(`mht/${mhtId}/comments/`);
  },

  spellcheck: (mhtId, language) => {
    return axios.post(`mht/${mhtId}/spellcheck/`, {
      language: language || 'en-US'
    });
  },

  listSegmentRevisions: (segmentId) => {
    return axios.get(`segments/${segmentId}/revisions/`);
  },

  listSegmentTerms: (segmentId) => {
    return axios.get(`segments/${segmentId}/terms/`);
  },

  listSegmentMemories: (segmentId) => {
    return axios.get(`segments/${segmentId}/memories/`);
  },


  saveSegments: (mhtId, userRole, segments) => {

    // console.log('MHTApi - saveSegments', segments)

    return axios.post(`mht/${mhtId}/segments/save/`, {
      role: userRole,
      segments: segments
    });
  },

  // depreciated
  saveSegment: (action, segments) => {
    return axios.post(`segments/save/`, segments);
  },
  // depreciated
  setSegmentStatus: (status, segments) => {
    return axios.post(`segments/status/${status}/`, {segments: segments});
  },
  // depreciated
  runSegmentAction: (status, segments) => {
    return axios.post(`segments/${status}/`, {segments: segments});
  },

  splitSegments: (segmentId, strings) => {
    return axios.post(`segments/split/`, {
      segment: segmentId,
      strings: strings
    });
  },
  mergeSegments: (segmentIds, strings) => {
    return axios.post(`segments/merge/`, {
      segments: segmentIds,
      source_html: strings.source_html,
      target_html: strings.target_html
    });
  },

  addSegmentComment: (segmentId, body) => {
    return axios.post(`comments/`, {
      segment: segmentId,
      body: body
    });
  },
  updateSegmentComment: (commentId, body) => {
    return axios.patch(`comments/${commentId}/`, {
      body: body
    });
  },
  deleteSegmentComment: (commentId) => {
    return axios.delete(`comments/${commentId}/`);
  },

  downloadComments: (id, onDownloadProgress) => {
    return axios({
      url: `mht/${id}/comments/export/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },

  downloadTargetFile: (params, onDownloadProgress) => {
    const { id, fontSizeAdjust, fontSize, fontFamilyAdjust, fontFamily, restoreStyle } = params;
    return axios({
      url: `mht/${id}/download/`,
      method: 'POST',
      responseType: 'blob',
      data: {
        font_size_adjust: fontSizeAdjust,
        font_size: fontSize,
        font_family_adjust: fontFamilyAdjust,
        font_family: fontFamily,
        restore_style: restoreStyle,
      },
      onDownloadProgress
    });
  },

  downloadBilingualExcel: (params, onDownloadProgress) => {
    const {id, role} = params;
    return axios({
      url: `mht/${id}/export/excel/`,
      method: 'POST',
      responseType: 'blob',
      onDownloadProgress,
      data: {role}
    });
  },

  downloadBilingualTmx: (id, onDownloadProgress) => {
    return axios({
      url: `mht/${id}/export/tmx/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },

  generatePreview: (id) => {
    return axios.get(`mht/${id}/preview/generate/`);
  },
  /* Segment Status & User Role */

  userRoles: userRoles,
  userRoleCanDo: userRoleCanDo,
  userRoleCanDoAnyOf: (userRole, actions) => {
    let canDo = false;
    let i = 0;
    if (actions) {
      while (!canDo && i < actions.length) {
        canDo = userRoleCanDo(userRole, actions[i]);
        i++;
      }
    }

    return canDo;
  },
  userRoleCanEditSegmentSource: (userRole, segmentStatus) => {
    return true;
  },
  userRoleCanEditSegmentTarget: (userRole, segmentStatus) => {
    let canEdit = true;
    // if (segmentStatus === 'Totrad') {
    //   canEdit = (userRole === userRoles.checker);
    // }
    return canEdit;
  },

  userRoleCanToggleSegmentStatus: (
    userRole, segmentIsLocked, segmentIsTotra
  ) => {

    let canConfirm = userRoleCanDo(userRole, 'confirm');

    if (canConfirm) {
      const {
        // ae,
        // coordinator,
        totra,
        translator,
        checker,
        editor,
        finalEditor,
        secondFinalEditor,
        dtp,
        secondDtp
      } = MHTApi.userRoles;

      switch (userRole) {
        case totra:
        case checker:
        case editor:
        case finalEditor:
        case secondFinalEditor:
        case dtp:
        case secondDtp:
          canConfirm = !segmentIsLocked;
          break
        case translator:
          canConfirm = !(segmentIsLocked || segmentIsTotra);
          break
        default:
          canConfirm = false
      }

    }
    return canConfirm;

  },
  confirmedRoleStatusMap: confirmedRoleStatusMap,
  changingRoleStatusMap: changingRoleStatusMap,
  isSegmentConfirmed: isSegmentConfirmed,
  toggleSegmentStatus: (userRole, segmentStatus) => {
    return (
      isSegmentConfirmed(userRole, segmentStatus)
    )
      ? changingRoleStatusMap[userRole]
      : confirmedRoleStatusMap[userRole]
  },
  getSegmentStatusChanging: (userRole, currentSegmentStatus) => {
    return changingRoleStatusMap[userRole] || currentSegmentStatus;
  }
};

function isSegmentConfirmed(userRole, segmentStatus, segmentIsMhtConfirmed) {
  return (
    segmentStatus === 'Confirmed' ||
    segmentStatus === confirmedRoleStatusMap[userRole]
  )
}

function userRoleCanDo(userRole, action) {
  let canDo;

  const {
    coordinator,
    totra,
    translator,
    checker,
    editor,
    finalEditor,
    secondFinalEditor,
    dtp,
    secondDtp,
    ae
  } = userRoles;

  switch (action) {
    case 'confirm':
      // also see: userRoleCanConfirmSegment
      canDo = !inArray(userRole, [
        coordinator, ae
      ]);
      break;
    case 'totra':
      canDo = !inArray(userRole, [
        translator, editor, finalEditor, secondFinalEditor
      ]);
      break;
    case 'lock':
      canDo = !inArray(userRole, [
        translator, editor, finalEditor, secondFinalEditor
      ]);
      break;
    case 'reCommitTm':
      canDo = userRole === coordinator;
      break;
    case 'tmSettings':
    case 'termSettings':
      canDo = inArray(userRole, [
        coordinator, ae
      ]);
      break;
    case 'bilingualFileDownloadTmx':
    case 'bilingualFileUpload':
      canDo = !inArray(userRole, [
        translator, checker, editor, finalEditor, secondFinalEditor
      ]);
      break;
    case 'postTranslate':
      canDo = inArray(userRole, [
        totra, dtp, secondDtp, coordinator, ae
      ]);
      break;
    case 'replaceTerms':
    case 'preTranslate':
      canDo = inArray(userRole, [
        totra, coordinator
      ]);
      break;
    case 'analyzeFile':
      canDo = inArray(userRole, [
        totra, coordinator, ae
      ]);
      break;
    case 'editSource':
      canDo = inArray(userRole, [
        coordinator,
        ae
      ]);
      break;
    case 'machineTranslateSegment':
      canDo = inArray(userRole, [
        totra, coordinator
      ]);
      break;

    default:
      // commentsDownload
      // sourceFileDownload
      // targetFileDownload
      // bilingualFileDownloadExcel
      // count
      // preview
      // copy
      // merge
      // split
      canDo = true;
  }

  return canDo;
}

export default MHTApi;
