import React from 'react';
import { WORDLIST_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import {
  CheckboxCellRenderer,
  LinkFieldCellRenderer,
  BooleanFieldCellRenderer
} from 'misc/cellRenderers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ListTable from 'components/core/ListTable';


class WordListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: '',
          field: '',
          suppressFilter: true,
          pinned: 'left',
          width: 30,
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: {
            handleCheck: this.props.handleSelect,
          },
        },
        {
          headerName: 'Name',
          field: 'name',
          width: 250,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: WORDLIST_DETAIL
          },
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 250,
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
        {
          headerName: 'Modified',
          field: 'modified',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        wrapperHeight="75vh"
        columnDefs={this.state.columnDefs}
        rowData={this.props.wordlists}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default WordListTable;
