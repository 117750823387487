import React from "react";
import './taFormField.css';
import PropTypes from 'prop-types';


export const TaFormFieldLabel = ({children,isRequired,htmlFor}) => {
  return children ? <label className="ta-form-field__label" htmlFor={htmlFor}>{children}{isRequired && (
      <span className="ta-form-field__label__required">*</span>
  )}</label> : null;
};

TaFormFieldLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};