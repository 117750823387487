import React from 'react';
import * as PropTypes from "prop-types";
import {
    TaModal, TaModalContent, TaModalContentMargin, TaModalFooter, TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaInputDropdown} from "../../taUi/taInputDropdown/taInputDropdown";
import {TaFormField} from "../../taUi/taFormField/taFormField";

class TaMhtEditorDownloadTargetFileModal extends React.Component {

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleSubmit = (e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            this.props.onConfirm({
                font_family: formData.get("font_family"),
                font_size: formData.get("font_size"),
                restore_style: formData.get("restore_style"),
            })
        };
    }

    render() {

        return (<TaModal
            isOpen={this.props.isOpen}
            onCancel={() => {
                if (typeof this.props.onCancel === "function") {
                    this.props.onCancel();
                }
            }}
        >
            {(!!this.props.isOpen) ? (
                <form onSubmit={this.handleSubmit}>
                    <TaModalHeader
                        onCancel={this.props.onCancel}
                        label={'Download Target File'}
                    />
                    <TaModalContent>
                        <TaModalContentMargin>
                            <TaFormField htmlFor={'font_size'} label={'Font Size'}>
                                <TaInputDropdown
                                    id={'font_size'}
                                    name="font_size"
                                    options={[
                                        {
                                            label: "Default",
                                            value: 'default'
                                        },
                                        {
                                            label: "Automatic font size",
                                            group: [
                                                {
                                                    label: "Adapt font size to text length",
                                                    value: 'auto'
                                                }
                                            ]
                                        },
                                        {
                                            label: "Fixed font size",
                                            group: [5, 5.5, 6.5, 7.5, 8, 9, 10, 10.5, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72].map(no => ({
                                                label: '' + no,
                                                value: no
                                            }))
                                        }
                                    ]}
                                    onChange={() => {
                                        this.setState({"font_size": this.state.font_size});
                                    }}
                                />
                            </TaFormField>
                            <TaFormField htmlFor={'font_family'} label={'Font Family'}>
                                <TaInputDropdown
                                    id={'font_family'}
                                    name="font_family"
                                    options={[{
                                        label: "Default", value: 'default'
                                    }, ...['Times New Roman', 'Arial', 'Calibri Light', 'ＭＳ 明朝', 'ＭＳ Ｐ明朝', 'ＭＳ ゴシック', 'ＭＳ Ｐゴシック', 'Meiryo UI', 'メイリオ', '游明朝', '游ゴシック', 'SimSun', 'SimHei'].map(name => ({
                                        label: name, value: name
                                    }))]}/>
                            </TaFormField>
                            <TaFormField htmlFor={'restore_style'} label={'Restore Style (experimental)'}>
                                <TaInputDropdown
                                    id={'restore_style'}
                                    name="restore_style"
                                    options={
                                        [
                                            {
                                                label: "Default (Target style)",
                                                value: 'default'
                                            },
                                            {
                                                label: "Source base style",
                                                value: 'common'
                                            },
                                        ]}/>
                                </TaFormField>
                        </TaModalContentMargin>
                    </TaModalContent>
                    <TaModalFooter>
                        <TaButton
                            type="reset"
                            icon={'clear'}
                            label={'Cancel'}
                            onClick={() => this.props.onCancel()}
                        />
                        <div/>
                        <TaButton
                            type="submit"
                            icon={'check'}
                            label={'Proceed'}
                        />
                    </TaModalFooter>
                </form>
            ) : null}
        </TaModal>);
    }
}

export default TaMhtEditorDownloadTargetFileModal;
