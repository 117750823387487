import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, change } from 'redux-form';
import { Box } from 'reactjs-admin-lte';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { JOB_TYPE_OPTIONS, JOB_LEVEL_OPTIONS } from 'misc/config';
import * as validators from 'misc/validators';
import SelectField from 'components/core/SelectField';
import CharField from 'components/core/CharField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import CheckboxField from 'components/core/CheckboxField';
import { getClientEntities } from 'selectors/entities';
import { getFieldOptionsByJobType } from 'selectors/options';
import {
  ServiceSelectOptionField,
  IndustrySelectOptionField,
  AESelectOptionField,
} from 'components/core/SelectOptionField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class TemplateEditBasicInfoFields extends PureRenderComponent {

  componentDidMount() {
    this.modifyFieldValue()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fieldOptions !== this.props.fieldOptions) {
      this.modifyFieldValue()
    }

    if (prevProps.client !== this.props.client) {
      this.modifyIndustryValue();
    }
  }
  modifyFieldValue = () => {
    if (this.props.fieldOptions
        .filter(f => f.value)
        .map(f => f.value)
        .includes(this.props.field) === false
    ) {
      this.props.change(this.props.form, 'field', '')
    }
  }

  modifyIndustryValue = () => {
    const client = this.props.clientEntities[this.props.client];
    const industry = client ? client.industry : '';
    this.props.change(this.props.form, 'industry', industry);
  }

  render() {
    const {
      isLoading,
      fieldOptions,
    } = this.props;

    return (
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Header border={true}>
          <Box.Title>Basic Information</Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
        <Box.Body>
          <Field
            name="type"
            label="Type"
            options={JOB_TYPE_OPTIONS}
            validate={[validators.required]}
            isRequired={true}
            component={SelectField.Labeled} />
          <Field
            name="aeSubject"
            label="AE Subject"
            type="text"
            validate={[
              validators.required,
              validators.maxLength150
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="subject"
            label="Subject"
            type="text"
            disabled={true}
            component={CharField.Labeled} />
          <Row>
            <Col lg={6}>
              <ServiceSelectOptionField
                validate={[validators.required]}
                isRequired={true} />
            </Col>
            <Col lg={6}>
              <Field
                name="level"
                label="Level"
                validate={[validators.required]}
                isRequired={true}
                options={JOB_LEVEL_OPTIONS}
                component={SelectField.Labeled} />
            </Col>
          </Row>
          <Field
            name="client"
            label="Client"
            validate={[validators.required]}
            isRequired={true}
            component={ClientAsyncSelectField.Labeled} />
          <Row>
            <Col lg={6}>
              <IndustrySelectOptionField
                validate={[validators.required]}
                isRequired={true} />
            </Col>
            <Col lg={6}>
              <Field
                name="field"
                label="Field"
                validate={[validators.required]}
                isRequired={true}
                options={fieldOptions}
                component={SelectField.Labeled} />
            </Col>
          </Row>
          <AESelectOptionField
            validate={[validators.required]}
            isRequired={true} />
          <Row>
            <Col lg={12}>
              <FormGroup>
                <ControlLabel>Options</ControlLabel>
                  <Field
                    className="mt-5 mb-10"
                    name="requireTotra"
                    label="Require ToTra"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="requireDtp"
                    label="Require DTP"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="strictDeadline"
                    label="Strict Deadline"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="futureJob"
                    label="Future Job"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="urgentJob"
                    label="Urgent Job"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="edinet"
                    label="EDINET"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="ifrs"
                    label="IFRS"
                    component={CheckboxField.Labeled} />
              </FormGroup>
            </Col>
          </Row>
        </Box.Body>
      </Box>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.form);
  const type = formSelector(state, 'type');
  const field = formSelector(state, 'field');
  const client = formSelector(state, 'client');
  const industry = formSelector(state, 'industry');
  return {
    type,
    field,
    client,
    industry,
    clientEntities: getClientEntities(state),
    fieldOptions: getFieldOptionsByJobType(state, type),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateEditBasicInfoFields);
