import React from "react";
import {TaTableCellGroup} from "components/taUi/taTable/taTableCellGroup";
import TaTableCell from "components/taUi/taTable/taTableCell";
import TaTableCellEditable from "components/taUi/taTable/taTableCellEditable";
import {TaText} from "components/taUi/taText/taText";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import {TaTableHeader} from "components/taUi/taTable/taTableHead";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaCard} from "components/taUi/taCard/taCard";
import {GlossaryAddRow} from "./GlossaryAddRow";

export function GlossaryEditor(
    {
        renderKey,
        languages,
        rows,
        selected,
        mutations,
        currentPage,
        rowsPerPage,

        isLoading,
        onAddRow,
        onCellEdit,
        onRowSelect,
        onRowUnselect,

        tableBodyRef,
        children
    }
) {

    const languageCellWidth = 100 / languages.length;
    const outerCellWidth = 48;

    return (
        <TaCard style={{position: 'absolute', left: '8px', right: '8px', top: '8px', bottom: '8px'}}>
            <TaTable scroll={'y'} flex={'auto'}>
                <TaTableHeader look={'card'} className={'ta-table--vertical-lines'}>
                    <TaTableRow>
                        <TaTableCell width={outerCellWidth} color={'light'}>#</TaTableCell>
                        <TaTableCellGroup>
                            {
                                (!languages.length) ? (
                                    <TaTableCell/>
                                ) : (
                                    languages.map((language) => (
                                            <TaTableCell
                                                key={language.key}
                                                width={languageCellWidth + '%'}
                                            >
                                                {language.alias}
                                            </TaTableCell>
                                        )
                                    )
                                )
                            }
                        </TaTableCellGroup>
                        <TaTableCell width={outerCellWidth}/>
                    </TaTableRow>
                    <GlossaryAddRow
                        languages={languages}
                        cellWidth={outerCellWidth}
                        onCommit={onAddRow}
                    />
                </TaTableHeader>
                <TaTableBody key={renderKey} className={'ta-table--vertical-lines'}>
                    {
                        (isLoading) ? (
                            <TaTableRow>
                                <TaTableCell>
                                    Loading...
                                </TaTableCell>
                            </TaTableRow>
                        ) : (

                            rows.map((row, rowIndex) => {
                                const isChecked = (
                                    selected &&
                                    selected.items &&
                                    selected.items[row.id]
                                );
                                const isMutated = mutations.index[row.id] || 0;
                                const reactKey = (isMutated) ? (row.id + '_' + isMutated) : row.id;
                                const term = (isMutated) ? Object.assign({}, row, mutations.data[row.id]) : row;
                                const rowNo = (currentPage > 1) ? rowsPerPage * currentPage - rowsPerPage + rowIndex + 1 : rowIndex + 1;

                                return (
                                    <TaTableRow key={reactKey}
                                                isSelected={isChecked}
                                    >
                                        <TaTableCell
                                            width={outerCellWidth}
                                            color={(term.is_pending) && 'alert'}
                                            onClick={() => onRowSelect(row.id)}
                                        >
                                            <TaText
                                                color={(term.is_pending) ? 'alert' : 'light'}
                                            >{rowNo}{(term.is_pending) && <span>&nbsp;&#9679;</span>}</TaText>
                                        </TaTableCell>
                                        {
                                            languages.map((language) => {
                                                return (
                                                    <TaTableCellEditable
                                                        key={language.key}
                                                        // error={error}
                                                        onFocus={onRowUnselect}
                                                        onBlur={(value) => onCellEdit(
                                                            language.key,
                                                            term[language.key],
                                                            value,
                                                            row,
                                                            rowIndex
                                                        )}
                                                    >
                                                        {term[language.key]}
                                                    </TaTableCellEditable>
                                                );
                                            })
                                        }
                                        <TaTableCell width={outerCellWidth}/>
                                    </TaTableRow>
                                );

                            })
                        )

                    }
                    <div ref={tableBodyRef}/>
                </TaTableBody>
                {children}
            </TaTable>
        </TaCard>
    )

}