import plainTextToHtml from "components/taUi/util/plainTextToHtml";

const canHaveTextNodeTags = [
  "html",
  "body",
  "title",
  "section",
  "nav",
  "article",
  "aside",
  "header",
  "footer",
  "address",
  "main",
  "p",
  "hr",
  "pre",
  "blockquote",
  "ol",
  "ul",
  "li",
  "dl",
  "dt",
  "dd",
  "figcaption",
  "div",
  "a",
  "em",
  "strong",
  "small",
  "s",
  "cite",
  "q",
  "dfn",
  "abbr",
  "ruby",
  "rt",
  "rp",
  "data",
  "time",
  "code",
  "var",
  "samp",
  "kbd",
  "sub",
  "sup",
  "i",
  "b",
  "u",
  "mark",
  "bdi",
  "bdo",
  "span",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "label",
  "button",
  "textarea",
  "option",
  "output",
  "legend",
  "caption",
  "table",
  "thead",
  "tbody",
  "tfoot",
  "tr",
  "td",
  "th",
  "summary",
]

export default function handleClipboard(editor) {

  //const clipboardPlugin = editor.plugins.get('Clipboard');
  const editingView = editor.editing.view;

  editingView.document.on('clipboardInput', (event, data) => {

    if (editor.isReadOnly) {
      return;
    }

    let content;
    const [textContent, textContentLength] = handleText(data.dataTransfer);
    const [htmlContent, htmlContentLength] = handleHtml(data.dataTransfer);

    if (textContentLength > htmlContentLength) {
      content = textContent
    } else {
      content = htmlContent
    }
    data.content = editor.data.htmlProcessor.toView(content);
  });
}

const handleText = (dataTransfer) => {
  let content = plainTextToHtml(dataTransfer.getData('text/plain'));
  // replace all 3 types of line breaks with a space
  content = content.replace(/(\r\n|\n|\r)/gm," ");
  // replace non-break-space with a space
  content = content.replace(/(&nbsp;|&#160;)/g," ");
  // remove <p> tags
  content = content.replace(/<p[^>]*?>(.*?)<\/p>/gms, '$1 '); // '$1</br>'
  //Replace all double white spaces with single spaces
  content = content.replace(/\s+/g," ");
  //Replace white space after Japanese dot
  content = content.replace(/。 /g,"。");
  // Remove spaces from start and end of string.
  content = content.trim();
  return [content, content.length];
}

const handleHtml = (dataTransfer) => {
  const copiedContent = dataTransfer.getData('text/html');
  const container = document.createElement("div");
  container.innerHTML = copiedContent

  const setSpecificStyles = (sourceElement, targetElement) => {
    const allowedProperties = [
      "color",
      "background-color",
      "background",
      "font-weight",
      "font-style",
      "text-decoration"
    ]
    if (sourceElement.tagName == "B") targetElement.style["font-weight"] = "bold";
    if (sourceElement.tagName == "I") targetElement.style["font-style"] = "italic";
    if (sourceElement.tagName == "U") targetElement.style["text-decoration"] = "underline solid";
    const style = sourceElement.getAttribute('style'); // Get the inline style attribute
    if (style) {
      // Parse inline styles
      const styleRules = style.split(';').map(rule => rule.trim()).filter(rule => rule);
      // Loop through styles and apply only allowed properties
      styleRules.forEach(rule => {
        const [property, value] = rule.split(':').map(part => part.trim());
        if (allowedProperties.includes(property)) {
          targetElement.style[property] = value;
        }
      });
    }
  }

  const traverseNodes = (node) => {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue) {
        const parent = node.parentNode;
        const span = document.createElement("span");
        span.textContent = node.nodeValue;
        setSpecificStyles(parent, span);
        spans.push(span);
    } else if (node.tagName && canHaveTextNodeTags.includes(node.tagName.toLowerCase())) {
        // Recursively process child nodes
        node.childNodes.forEach(traverseNodes);
    }
  }

  const spans = [];
  traverseNodes(container);
  const paragraph = document.createElement('p');
  spans.forEach(span => paragraph.appendChild(span));
  return [paragraph.innerHTML, paragraph.innerText.length]
}